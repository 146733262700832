.banner{
    background: #333333;
    background-size: cover;
    height: 572px;
    display: flex;
    align-items: center; 
}

.banner h1{
    color: #fff;
    font-weight: 400;
    font-size: 64px;
    line-height: 70px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.banner p{
    color: #fff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0;
}

.banners .slick-dots{
    bottom: 15px;
}

.banners .slick-dots li.slick-active button{
    background: #41AAAE;
}
  
@media (max-width: 1070px) {
    .banner{
        height: 450px;
    }
    
    .banner h1{
        font-size: 40px;
        line-height: 40px;
    }

}