.vantagens{
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    margin-top: 140px;
}

.vantagem-box{
    background: #333333;
    width: 700px;
    position: relative;
    color: #fff;
}

.vantagem-box::before{
    content: " ";
    background: #333333;
    height: 70px;
    width: 630px;
    position: absolute;
    bottom: -70px;
}

.vantagem-box:after{ 
    content: " ";
    width: 0; 
    height: 0; 
    border-left: 70px solid transparent;
    border-right: 70px solid transparent; 
    border-top: 70px solid #333333; 
    bottom: -70px;
    position: absolute;
    right: 0;
}
 
.vantagem-box .vantagem{
    padding: 40px 50px 0px 150px;
    position: relative;
}

.vantagem-box .vantagem img{
    position: absolute;
    left: inherit;
    right: 35px;
    box-shadow: none;
    top: 25px;
}

.vantagem-box .vantagem h3{
    font-weight: 700;
    font-size: 40px;
    margin: 0px;
}

.vantagem-box .vantagem p{
    font-size: 14px;
    line-height: 19px;
    margin: 0;
}

.vantagem-box .divider{
    width: 100%;
    height: 1px;
    background: #6D6D6D;
    margin-top: 40px;
}

.vantagens img{  
    position: absolute;
    z-index: 1;  
    left: -15%;
    top: -15%;
    box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.1);
}


.quem-somos{
    background: #D9D9D9;
    margin-top: -50px;
    padding: 80px 0 150px 0;
}

.quem-somos h2{
    margin-bottom: 50px;
}

.quem-somos p{
    color: #5B5B5B;
}

.quem-somos button{
    margin-top: 40px;
    font-size: 18px;
}

.missao-visao-valores{
    display: flex;
    justify-content: space-between;  
    margin-top: -90px;
}

.missao-visao-valores .card{
    background: #333;
    color: #fff;
    flex-basis: 32%;
    height: 290px;
    position: relative;
}

.missao-visao-valores .card.missao{
    background: #41AAAE;
} 
.missao-visao-valores .card.valores{
    background: #0B4696;
}
 
.missao-visao-valores .card h3{
    margin-left: 40px;
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 0;
}

.missao-visao-valores .card p,
.missao-visao-valores .card ul{
    margin-left: 40px;
}

.missao-visao-valores .card img{
    position: absolute;
    top: 30px;
    right: 30px;
}

@media (max-width: 1240px) {
    .vantagens img{
        z-index: -1;
    }
}

@media (max-width: 970px) { 

    .vantagens {
        flex-direction: column;
        padding: 0;
    }

    .vantagens img{
        display: none;
    }

    .vantagem-box{
        width: 90%; 
        margin: 0 auto;
    }
    .vantagem-box::before,
    .vantagem-box::after{
        display: none;
    }

    .vantagem-box { 
        padding-bottom: 55px;
    }
    
    .vantagem-box .vantagem {
        padding: 40px 50px 0px 50px;
    }

    .missao-visao-valores .card{
        height: 230px;
    }

    .missao-visao-valores .card h3{
        font-size: 30px;
        position: relative;
        z-index: 2;
    }

    .missao-visao-valores .card p, .missao-visao-valores .card ul{
        position: relative;
        z-index: 2;
        padding: 0;
        font-size: 14px;
    }

    .missao-visao-valores .card img{
        z-index: 1;
        width: 40%;
    } 

    .vantagem-box .vantagem h3 {
        font-size: 30px;
    }

}

@media (max-width: 700px) { 
    .missao-visao-valores { 
        flex-direction: column;
    }
    
    .missao-visao-valores .card {   
        flex-basis: inherit; 
        height: 200px;
        overflow: hidden;
    }
    
    .missao-visao-valores .card img {
        width: 15%;
    }
}