.contato{
    padding-top: 80px;
}

.contato .container{
    display: flex;
    justify-content: space-between;  
}

.contato .col{
    flex-basis: 50%;
}

.contato h2{
    margin-top: 100px;
}

.contato p{
    color: #333333;
}

.contato .divider{
    margin: 30px 0;
    width: 100px;
    height: 1px;
    background: #CECECE;
}

.contato .email{
    margin: 0;
}

.contato .phone{
    font-weight: 600;
    font-size: 35px;
    margin: 0;
}

.contato .phone small{
    font-size: 15px;
    font-weight: 400;
}

.contato form{
    border: 1px solid #E0E0E0;
    padding: 20px 30px;
    background: #fff;
    position: relative;
    z-index: 1;
    color: #646464;
}

.contato form p{
    font-size: 14px;
} 

.contato form .line{
    display: flex;
    flex-direction: column;
    margin: 30px 0;
}

.contato form .line label{
    font-size: 15px; 
}

.contato form .line input{
    height: 30px;
    padding: 5px 10px;
}

.contato form  button{
    font-size: 18px;
    padding: 0 40px;
}

.contato form .line input,
.contato form .line textarea{
    border: 1px solid #D3D3D3;
    margin-top: 5px;
    font-family: 'Inter', sans-serif;
}

.contato form .line textarea{
    padding: 10px;
}
 
.contato form:after{ 
    content: " ";
    width: 0;
    height: 0;
    border-left: 60px solid transparent;
    border-right: 60px solid #333;
    border-top: 60px solid transparent;
    bottom: -1px;
    position: absolute;
    right: -1px;
}


.contato .sucesso{
    text-align: center;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
 
.contato .sucesso h3{
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 25px;
}

.contato .sucesso p{
    margin: 0;
}

.contato .errorMessage{
    background: rgb(255, 203, 203);
    padding: 10px;
    margin-bottom: 10px;

}

.contato .errorMessage span{
    color: rgb(216, 75, 75);
}

footer{
    background-color: #333333;
    margin: 0;
    color: #fff;
    height: 190px;
    margin-top: -140px;
}

footer ul{
    display: flex;
    list-style: none;
    padding: 0;
    padding-top: 33px;
}

footer ul li .item{
    width: 40px;
    height: 40px;
    background: #000;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

footer ul li .item.facebook{
    background: #4267B2;
}

footer ul li .item.instagram{
    background: #E1306C;
}

footer ul li .item.whatsapp{
    background: #128C7E;
}

footer ul li .item.facebook:hover{
    background: #385898;
}

footer ul li .item.instagram:hover{
    background: #c42a5d;
}

footer ul li .item.whatsapp:hover{
    background: #0f7468;
}

footer p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 33px;
}

.wordvirtua{
    background-color: #101224;
    height: 60px;
    display: flex;
    align-items: center; 
    background-repeat: no-repeat;
    background-position: right;
}

@media (max-width: 700px) { 
    .contato .container { 
        flex-direction: column;
    }

    .contato h2 {
        margin-top: 0;
    }

    .contato form {
        margin-top: 70px;
    }
    footer{
        margin-top: 0;
    }
    .contato form:after{
        display: none;
    }
}