@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Oswald:wght@300;400;700&display=swap');

body{
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
}

header, h1, h2, h3, button, footer{
  font-family: 'Oswald', sans-serif;
}

h2{
  color: #333333;
  font-weight: 700;
  font-size: 48px;
  line-height: 71px;
  margin-bottom: 15px;
}

.container{
  width: 1200px;
  margin: 0 auto;
  position: relative;
}

button{
  background: #0B4696;
  border: none;
  height: 40px;
  color: #fff;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px
} 

button:hover{
  background: #093b81;
}

button.whatsapp{
  background: #128C7E;
}

button.whatsapp:hover{
  background: #0f776b;
}
button img{
  margin-right: 10px;
}


.slick-dots li button{
  width: 15px;
  height: 15px;
  background: #D9D9D9
}

.slick-dots li.slick-active button{
  background: #333;
}

.slick-dots li button::before{
  display: none;
}

 
@media (max-width: 1240px) {
  .container{
    width: 90%;
    padding: 0;
  }  

  h2{
    font-size: 28px;
    line-height: 71px; 
  }

  .services .slick-list {
    overflow: hidden !important;
  }

  /* .slick-slider .container{
    display: none;
  } */

 

}
* {
  scroll-margin-top: 160px;
}