header{
    width: 1200px;
    margin: 0 auto;
    left: 0;
    right: 0;
    background: #D9D9D9;
    box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.05);
   
    padding: 7px 15px;
    margin-top: 20px;
    position: fixed;
    z-index: 99;
}

header .header-container{
    display: flex;
    justify-content: space-between;
}

header div{
    display: flex;
    align-items: center;
}

header div ul{
    display: flex;
    list-style: none;
}

header div ul li{
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
}

header div ul li:hover{
    background: rgba(0, 0, 0, 0.05);
}

header div span.phone{
    font-weight: 500;
    font-size: 16px;
    margin-right: 15px;
}

header div span.phone small{
    font-weight: 300;
}

header div button.whatsapp{
    margin-left: 10px;
}

header .mobile-menu{
    display: none;
}

header button.mobile-btn{
    display: none;
}

header a{
    text-decoration: none;
}

@media (max-width: 1240px) {
    header{
        width: 90%;
    }
}

@media (max-width: 1070px) {

    header .logo{
        width: 120px;
    }

    header .left ul,
    header .right button{
        display: none;
    }
 
    header .mobile-menu{
        display: block;
        width: 100%;
    }

    header .mobile-menu ul {
        display: block;
        padding: 0;
        list-style: none;
    }

    header .mobile-menu button{
        width: 100%;
        margin: 0 !important;
    }

    header div ul li { 
        border-bottom: 1px solid #ccc;
    }
    header div ul li:last-child{
        border: none;
    }
    
    header button.mobile-btn{
        display: block;
        width: 40px;
        height: 40px;
        background: #333;
    }

    header button.mobile-btn span{
        font-size: 20px;
    }

    .mobile-btn {
        width: 40px;
        height: 40px;
        position: relative; 
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
    }

    .mobile-btn span {
        display: block;
        position: absolute;
        height: 5px;
        width: 25px;
        background:#d9d9d9; 
        opacity: 1;
        left: 0;
        left: 8px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
    }
      

    .mobile-btn span:nth-child(1) {
        top: 8px;
    }
    
    .mobile-btn span:nth-child(2),.mobile-btn span:nth-child(3) {
        top: 17px;
    }
    
    .mobile-btn span:nth-child(4) {
        top: 26px;
    }
    
    .mobile-btn.open span:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
    }
    
    .mobile-btn.open span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    
    .mobile-btn.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    
    .mobile-btn.open span:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
    }
      

}