.services p{
    font-weight: 400;
    font-size: 16px;
    line-height: 19px; 
    color: #989898;
    margin: 0;
}

.service-banners{
    width: 1200px;
    margin: 50px auto;
}

.service-banners .item{
    width: 338px;
    height: 372px;
}

.service-banners .title{
    background: #D9D9D9;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    font-family: 'Oswald', sans-serif;
    position: absolute;
    bottom: -16px;
    padding: 10px 20px;
}

.service-banners img{
    box-shadow: 0px 0px 25px 10px rgba(0, 0, 0, 0.05);
}

.services .slick-list {
    overflow: inherit;
}

.service-banners .slick-dots{
    position: relative;
    margin-top: 20px;
}

@media (max-width: 1240px) {
    .service-banners {
        width: 90%; 
    }
}